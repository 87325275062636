<template scroll="false">
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Clienti"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <div 
        style="flex-wrap: wrap; display: flex; justify-content: flex-end; width: 75vw;"
      >
        <ExportExcelTable
          :worksheetName="worksheetName"
          :fileName="fileName"
          :fieldsToExport="fieldsToExport"
          :fetch="fetchExcel"
        > </ExportExcelTable>
        <v-btn text @click="handleSms()" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-cellphone-message</v-icon> Invia SMS
        </v-btn>
        <v-btn text @click="applyAllWhatsapp()">
          <v-icon class="mr-2">mdi-whatsapp</v-icon> Applica a tutti
        </v-btn>
        <v-btn text @click="handlePrint()">
          <v-icon class="mr-2">mdi-printer</v-icon> INFO GDPR
        </v-btn>
        <v-btn text link @click="goToNew" :disabled="isLicenseBlocked">
          <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
        </v-btn>
      </div>

      <StandardDialog
        v-model="sendSmsDialog"
        :dialog-height="null"
        title="Invia Messaggio"
        dialog-max-width="1000px"
      >
        <CustomersAutocomplete
          class="mt-2"
          v-model="customerSms"
          return-object
          :clearable="false"
          prepend-icon="mdi-account-arrow-right"
          :append-outer-icon="!!customerSms ? 'mdi-pencil' : undefined" 
        ></CustomersAutocomplete>

        <SendSmsModel 
          class="mt-4"
          :customers="[customerSms]"
          :numbers="!!customerSms ? [customerSms.prefixCellphone + customerSms.cellphone] : undefined"
          @input="handleSmsInput"
        ></SendSmsModel>

        <template v-slot:footer>
          <v-spacer></v-spacer>
            <v-btn 
              text 
              color="error" 
              @click="sendSmsDialog = false" 
            >Annulla</v-btn>
        </template>
      </StandardDialog>
    </template>
    <template v-slot:toolbar-extension> </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Descrizione: {{ filter.value }}
          </template>

          <template v-slot:custom-gender="{filter}">
            <v-row class="pa-6">
              <v-col>
                <v-switch
                  v-model="filter.value"
                  false-value="male"
                  true-value="female"
                  color="pink"
                  :label="filter.value == 'male' ? 'Uomo' : 'Donna'"
                  :class="filter.value == 'male' ? 'custom-blue-switch' : 'custom-pink-switch'"
                  flat
                >
                </v-switch>
              </v-col>
            </v-row>
          </template>
          <template v-slot:chip-custom-gender="{filter}">
            Sesso: {{filter.value == 'male' ? 'Uomo' : 'Donna'}}
          </template> 

          <template v-slot:custom-interests="{filter}">
            <InterestsAutocomplete
              v-model="filter.value"
              :multiple="true"
              :dense="true"
              :return-object="false"
            ></InterestsAutocomplete>  
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento clienti"
          :headers="headers"
          :loading="loading || loadingHeaders"
          :translator="translator"
          :items="filteredCustomers"
          @edit="handleEdit"
          @delete="handleDelete"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          actions-width="80px"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
        >
        </TypeDataTable>
      </div>
      <StandardDialog
        v-model="showBarcode"
        :title="titleDelete"
        :dialog-height="null"
        dialog-max-width="500px"
        persistent
      >
        <ManualBarcodeInput
          v-model="barcodePermission"
          @confirm="deletePermission"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import customerService from "@/services/customers/customers.service.js";
import { SearchBar } from "likablehair-ui-components";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import SendSmsModel from '@/components/calendar/SendSmsModel.vue';
import _customersRegistryList from "./filters/customersRegistryList.js";
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue';
import InterestsAutocomplete from '@/components/common/InterestsAutocomplete.vue'
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import dateUtils from "@/mixins/common/dateUtils"
import operatorService from '@/services/operators/operators.service.js';


export default {
  name: "CustomersRegistryList",
  components: {
    FullScreenDialog,
    SearchBar,
    TypeDataTable,
    StandardMenu,
    AdvancedFilter,
    StandardDialog,
    ManualBarcodeInput,
    CustomersAutocomplete,
    SendSmsModel,
    ExportExcelTable,
    InterestsAutocomplete
  },
  mixins:[dateUtils],
  data: function() {
    let props = this.$router.resolve({ name: "Registries" });

    return {
      dialog: true,
      loading: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      customers: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      resizeListener: undefined,
      printingCustomer: undefined,
      customerListFilter: [],
      customerSms: undefined,
      sendSmsDialog: false,
      filterTypes: [
        { type: 'string', operator: 'like', field: 'city', name: 'Città', label: 'Città', color: "", value: undefined, icon: "mdi-city-variant" },
        { type: 'string', operator: 'like', field: 'fiscalCode', name: 'Codice Fiscale', label: 'Codice Fiscale', color: "", value: undefined },
        { type: 'date', operator: 'equal', field: 'birthday', name: 'Data di Nascita', label: 'Data di Nascita', color: "", value: undefined, icon: "mdi-cake-variant" },
        { type: 'string', operator: 'equal', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: "", value: undefined, icon: "mdi-cellphone" },
        { type: 'string', operator: 'equal', field: 'telephone', name: 'Telefono', label: 'Telefono', color: "", value: undefined, icon: "mdi-deskphone" },
        { type: 'boolean', operator: 'equal', field: 'sendByWhatsapp', name: 'Invio messaggi tramite Whatsapp', label: 'Invio messaggi tramite Whatsapp', color: "", value: false, icon: "mdi-whatsapp" },
      ],
      advanceFilters: [
        { type: 'custom', operator: 'equal', field: 'gender', name: 'Sesso', label: 'Sesso', color: "", value: 'male', icon: "mdi-gender-male-female" },
        { type: 'string', operator: 'like', field: 'email', name: 'E-Mail', label: 'E-Mail', color: "", value: undefined, icon: 'mdi-email' },
        { type: 'custom', operator: 'custom', field: 'interests', name: 'Interessi', label: 'Interessi', color: "", value: [], icon: 'mdi-heart-outline' }
      ],
      externalFilter: { type: 'custom', operator: 'custom', field: 'customer', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      barcodePermission: undefined,
      showBarcode: false,
      selectedDeleteCustomer: undefined,
      isLicenseBlocked: undefined
    };
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function() {
    this.loadHeaders();
    this.filtersValue = this.filters;
    this.loadCustomers();

    this.customerListFilter = _customersRegistryList;

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.9;
    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight *0.9;
    };
    this.resizeListener = this.resizeListener.bind(this);

    this.fieldsToExport = customerService._fieldsToExport();
    this.worksheetName = "Export Clienti ";
    this.fileName =
      "Clienti_" + this._buildDateToExport(new Date()) +
      ".xls";
    window.addEventListener("resize", this.resizeListener);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    loadHeaders() {
      this.loadingHeaders = true;
      customerService.fields().then((headers) => {
        this.headers = headers;
        this.loadingHeaders = false;
      });
    },
    loadCustomers() {
      this.loading = true;
      let filters = [...this.filtersValue];

      customerService.list(this.page, this.rowPerPage, filters).then((results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage;
        }

        if (this.page != results.page) {
          this.page = results.page;
        }

        this.totalPages = results.totalPages;
        if (this.totalPages < this.page) {
          this.page = this.totalPages;
        }
        this.customers = results.rows;
        this.loading = false;
      });
    },
    handleEdit(customer) {
      this.$router.push({
        name: "CustomersRegistryEditForm",
        params: {
          id: customer.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "CustomersRegistryList" }).href,
          pathName: "CustomersRegistryList",
        },
      });
    },
    handleEditDoubleClick(row, {item: customer}) {
      this.$router.push({
        name: "CustomersRegistryEditForm",
        params: {
          id: customer.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "CustomersRegistryList" }).href,
          pathName: "CustomersRegistryList",
        },
      });
    },
    deletePermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Registries", "delete").then((result) => {
        if (result) {
          customerService.archive(this.selectedDeleteCustomer).then(() => {
            this.loadCustomers();
          })
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handleDelete(customer) {
      this.selectedDeleteCustomer = customer
      this.barcodePermission = undefined
      this.showBarcode = true
    },
    handleSms() {
      this.sendSmsDialog = true
    },
    applyAllWhatsapp() {
      customerService.applyAllWhatsapp(this.filtersValue).then(() => {
        this.loadCustomers();
        this.$delegates.snackbar('Operazione eseguita con successo')
      }).catch((err) => {});
    },
    handleSmsInput() { 
      this.sendSmsDialog = false
      this.customerSms = undefined
    },
    goToNew() {
      this.$router.push({
        name: "CustomersRegistryNewForm",
        query: {
          pathToGoBack: this.$router.resolve({ name: "CustomersRegistryList" })
            .href,
        },
      });
    },
    handlePrint(customerToPrint) {
      if (!!customerToPrint) this.printingCustomer = customerToPrint;
      customerService
        .printGdpr(customerToPrint)
        .then((result) => {
          this.printingCustomer = undefined;
        })
        .catch((err) => {});
    },
    translator(fieldName, fieldValue) {
      if (fieldName == "job" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "gender" && !!fieldValue) {
        return this.$translations.t("enums.customers.gender." + fieldValue);
      }
      if (fieldName == "hairColor" && !!fieldValue) {
        return fieldValue["name"];
      }
      if (fieldName == "hairType" && !!fieldValue) {
        return fieldValue["name"];
      }
    },
    applyFilters(filters) {
      this.loadCustomers();
    },
    async fetchExcel(){
      let filters = [...this.filtersValue];

      let customers = (await customerService.list(undefined, 15000, filters)).rows

      return customers
    },
  },
  computed: {
    filteredCustomers() {
      return this.customers;
    },
    titleDelete() {
      if (this.selectedDeleteCustomer)
        return "Elimina " + this.selectedDeleteCustomer.firstname + " " + this.selectedDeleteCustomer.lastname
      else 
        return "Elimina Cliente"
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.loadCustomers();
    },
    rowPerPage() {
      this.loadCustomers();
    },
    customerListFilter(newVal) {
      this.customerListFilter = newVal;
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>

<style>
.custom-blue-switch .v-input--selection-controls__input div {
  color: blue !important;
  caret-color: blue !important;
}
.custom-pink-switch .v-input--selection-controls__input div {
  color: hotpink !important;
  caret-color: hotpink !important;
}
</style>
