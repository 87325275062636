import customerService from "@/services/customers/customers.service.js";

export default [
  {
    name: "fiscalCode",
    type: "string",
    text: "Codice Fiscale",
    icon: "mdi-medical-bag",
  },
  {
    name: "iva",
    type: "string",
    text: "Partita IVA",
    icon: "mdi-city-variant",
  },
  {
    name: "birthday",
    type: "object",
    text: "Compleanno",
    icon: "mdi-cake-variant",
    fieldToShow: "value",
    fetcher: customerService.monthList,
  },
  {
    name: "card",
    type: "string",
    text: "Tessera",
    icon: "mdi-credit-card",
  },
  {
    name: "cardExist",
    type: "boolean",
    text: "Con Tessera",
    icon: "",
    value: false
  },
  {
    name: "newCustomers",
    type: "date",
    text: "Nuovi Clienti",
    icon: "mdi-account-multiple",
  },
]